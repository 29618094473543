import React, { useState } from 'react'
import Alert from './Alert'

const Packages = () => {
    
    const [showAlert, setShowAlert] = useState(false)
    const [color, setColor] = useState(false)
    const [alertMessage, setAlertMessage] = useState('Wrong Credentials')

    const [credentials, setCredentials] = useState({ email: "", package_id: "" })

    const { email ,  package_id } = credentials;


    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(credentials)
        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm('Continue?');
        if (!isConfirmed) {
            return; // Stop the form submission if the user cancels
        }
        // Proceed with the form submission logic
        try {
            console.log('send api');
            console.log(sessionStorage.getItem('PIP-admin'))
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/update/package`, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${sessionStorage.getItem('PIP-admin')}`
                },
                body: JSON.stringify({ email: email, package_id: package_id  })
            });
            console.log(response)
            const data = await response.json();
            console.log(data)
            if(response.ok){
                setAlertMessage(data.message)
                setColor("green")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            }
            else{
                setAlertMessage(data.message)
                setColor("red")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }
    }
    
    return (
        <div>
            {showAlert && <Alert message={alertMessage} color={color} />}
            <form onSubmit={handleSubmit} className='search-div'>
            
                <div>
                    <label htmlFor="search">Email</label>
                    <input onChange={onChange} value={email} type="email" name='email' id='email' required/>
                </div>
                <div>
                    <label htmlFor="search">Package</label>
                    <input onChange={onChange} value={package_id} type="number" name='package_id' id='package_id' required/>
                </div>
                <button type='submit'>Change</button>
            </form>
        </div>
    )
}

export default Packages