import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import AddVideo from './AddVideo'
import Payouts from './Payouts'
import Packages from './Packages'

const Admin = () => {
    // POST     /admin/login     
    // PATCH    /admin/update/package    
    // PATCH    /admin/update/balance    
    // GET      /admin/video/information/:videoID    
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
    if(!sessionStorage.getItem('PIP-admin')){
        navigate('/login')
    }
}, [navigate])


    return (
        <div>
            <div className='top-bar'>
                <p>Admin</p>
                <Link to='/' className={`lis ${location.pathname==='/'&&'green'}`}>Packages</Link>
                <Link to='/payouts' className={`lis ${location.pathname==='/payouts'&&'green'}`}>Payouts</Link>
                <Link to='/video' className={`lis ${location.pathname==='/video'&&'green'}`}>Add Video</Link>
                <li  className={`cred lis`} onClick={()=>{
                    sessionStorage.clear()
                    navigate('/login')
                }}>Logout</li>
            </div>

            <div>
                {location.pathname==='/'&&<Packages/>}
                {location.pathname==='/payouts'&&<Payouts/>}
                {location.pathname==='/video'&&<AddVideo/>}
            </div>
        </div>
    )
}

export default Admin