import React, { useState } from 'react'
import Alert from './Alert'

const Payouts = () => {
    const [showAlert, setShowAlert] = useState(false)
    const [color, setColor] = useState(false)
    const [alertMessage, setAlertMessage] = useState('Wrong Credentials')

    const [credentials, setCredentials] = useState({ email: "", deduct_balance_value: "" })

    const { email ,  deduct_balance_value } = credentials;


    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log(credentials)
        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm('Continue?');
        if (!isConfirmed) {
            return; // Stop the form submission if the user cancels
        }
        // Proceed with the form submission logic
        try {
            console.log('send api');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/update/balance`, {
                method: "PATCH",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${sessionStorage.getItem('PIP-admin')}`
                },
                body: JSON.stringify({ email: email, deduct_balance_value: deduct_balance_value })
            });
            console.log(response)
            const data = await response.json();
            console.log(data)
            if(response.ok){
                setAlertMessage(data.message)
                setColor("green")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            }
            else{
                setAlertMessage(data.message)
                setColor("red")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }

    }

    return (
        <div>
             {showAlert && <Alert message={alertMessage} color={color} />}
            <form onSubmit={handleSubmit} className='search-div'>
                <div>
                    <label htmlFor="search">Email</label>
                    <input onChange={onChange} value={email} type="text" name='email' id='email' required/>
                </div>
                <div>
                    <label htmlFor="deduct_balance_value">Deduct Amount</label>
                    <input onChange={onChange} value={deduct_balance_value} type="number" name='deduct_balance_value' id='deduct_balance_value' required/>
                </div>
                <button type='submit'>Deduct</button>
            </form>
        </div>
    )
}

export default Payouts