import React, { useEffect, useState } from 'react'
import FormInput from './FormInput'
import Alert from './Alert'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const Login = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [fetching, setfetching] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState('Wrong Credentials')

    const [credentials, setCredentials] = useState({ username: "", password: "" })

    const { username ,  password } = credentials;

    useEffect(() => {
        if (sessionStorage.getItem('PIP-admin')) {
            navigate('/')
        }
    }, [location, navigate])

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }


    const handleSubmit = async (e) => {
        setfetching(true)
        e.preventDefault()
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username : username, password: password })
            })

            console.log(response);
            const json = await response.json();
            console.log(json)

            if (json.jwt) {
                console.log(json.jwt)
                // Save auth-token & Redirect
                sessionStorage.setItem("PIP-admin", json.jwt.token);
                setfetching(false)
                navigate('/')

            }
            else if (!response.ok) {

                setAlertMessage(json.message)
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 3000);
            }
            else {
                console.log("error")
            }
            setfetching(false)
        } catch (error) {
            console.log(error);
            setAlertMessage('OOPs Some Error Occured')
            setShowAlert(true)
            setTimeout(() => {
                setShowAlert(false)
            }, 3000);
        }
        setfetching(false)
    }

    return (
        <main className='sign-up-main'>
            {showAlert && <Alert message={alertMessage} color='red' />}
            <section id='sign-up'>
                <h2>Admin Log In</h2>
                <form onSubmit={handleSubmit}>
                    <div>

                        <div>
                            <div className='left'>
                                <FormInput label='User Name' value={username} name='username' type='text' onChange={onChange} />
                            </div>



                            <div>
                                <FormInput label='Password' value={password} name='password' type='password' onChange={onChange} />
                            </div>
                        </div>

                    </div>

                    <button disabled={fetching}>Log in to my account</button>
                </form>
            </section>

        </main>
    )
}

export default Login