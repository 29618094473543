
import './App.css';
import Admin from './components/Admin';
import Login from './components/Login';
import {
  BrowserRouter as Router,
  Routes, 
  Route 
} from "react-router-dom";

function App() {
  return (
    <Router>

      <Routes>

        <Route path="/" element={<><Admin /></>} />
        <Route path="/payouts" element={<><Admin /></>} />
        <Route path="/video" element={<><Admin /></>} />
        <Route path="/login" element={<><Login /></>} />

      </Routes>

    </Router>
  );
}

export default App;
