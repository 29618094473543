import React, { useState } from 'react'
import Alert from './Alert'

const AddVideo = () => {
    const [showAlert, setShowAlert] = useState(false)
    const [color, setColor] = useState(false)
    const [alertMessage, setAlertMessage] = useState('Wrong Credentials')
    var videoId;
    const [credentials, setCredentials] = useState({ alink: ""})

    const { alink } = credentials;


    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }
    const convertToEmbedUrl = async (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)/;
        const shortUrlRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^&\s]+)/;

        if (regex.test(url)) {
            videoId = url.match(regex)[1];
        } else if (shortUrlRegex.test(url)) {
            videoId = url.match(shortUrlRegex)[1];
        }

        if (videoId) {
            const embedUrl = `https://www.youtube.com/embed/${videoId}`;
            console.log('Converted embedUrl:', embedUrl); // Debug line
            return embedUrl;
        }

        return ''; // Return an empty string if the URL is invalid
    };
    const handleSubmit = async (e) => {
        e.preventDefault()
        const url = convertToEmbedUrl(alink)
        console.log(url)
        // eslint-disable-next-line no-restricted-globals
        const isConfirmed = confirm('Continue?');
        if (!isConfirmed) {
            return; // Stop the form submission if the user cancels
        }
        // Proceed with the form submission logic
        try {
            console.log('send api');
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/video/add`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${sessionStorage.getItem('PIP-admin')}`
                },
                body: JSON.stringify({ link: alink })
            });
            console.log(response)
            const data = await response.json();
            console.log(data)
            if(response.ok){
                setAlertMessage(data.message)
                setColor("green")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            }
            else{
                setAlertMessage(data.message)
                setColor("red")
                setShowAlert(true)
                setTimeout(() => {
                    setShowAlert(false)
                }, 5000);
            }
        } catch (error) {
            console.error('Error during API call:', error);
        }

    }

    return (
        <div>
            {showAlert && <Alert message={alertMessage} color={color} />}
            <form onSubmit={handleSubmit} className='search-div'>
                <div>
                    <label htmlFor="alink">Link</label>
                    <input onChange={onChange} type="text" name='alink' id='alink' required />
                </div>
                <button type='submit'>Add Video</button>
            </form>
        </div>
    )
}

export default AddVideo